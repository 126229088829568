import React, { PureComponent } from 'react'
import { css } from '@emotion/core'

const imgStyles = css`
  display: block;
`

const fadeStartStyles = css`
  opacity: 0;
  transition: opacity 0.2s ease-in;
`

const fadeEndStyles = css`
  opacity: 1;
`

const imageContainerStyles = css`
  position: relative;
`

const forcedDimensionsStyles = css`
  width: 100%;
  overflow: hidden;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const loaderStyles = css`
  background-color: #e8e8e8;
`

const coverStyles = css`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  img {
    opacity: 0;
  }
`

declare const window: any

interface IImageProps {
  src: string
  alt?: string
  cover?: boolean
  showLoader?: boolean
  animateLoader?: boolean
  loaderColor?: string
  loaderHeightPercent?: number
  fadeIn?: boolean
  fadeInTime?: string
  heightPercent?: number
}

interface IImageState {
  isImageLoaded: boolean
}

class Image extends PureComponent<IImageProps, IImageState> {
  state = {
    isImageLoaded: false
  }

  constructor(props: IImageProps) {
    super(props)
    this.state = {
      isImageLoaded: false
    }
  }

  onImageLoad = () => {
    this.setState({ isImageLoaded: true })
  }

  componentDidMount() {
    const imgSrc = this.props.src
    if (imgSrc) {
      let img = new window.Image()
      img.src = imgSrc
      img.onload = this.onImageLoad
    }
  }

  render() {
    const {
      src,
      alt,
      cover,
      showLoader,
      animateLoader,
      loaderColor,
      loaderHeightPercent,
      fadeIn,
      fadeInTime,
      heightPercent,
      ...rest
    } = this.props
    const fadeInStyles = [
      fadeIn ? fadeStartStyles : null,
      fadeIn && this.state.isImageLoaded ? fadeEndStyles : null,
      fadeInTime
        ? css`
            transition: opacity ${fadeInTime} ease-in;
          `
        : null
    ]

    return (
      <div
        css={[
          imageContainerStyles,
          typeof heightPercent === 'number' ? forcedDimensionsStyles : null,
          typeof loaderHeightPercent === 'number' && !this.state.isImageLoaded
            ? forcedDimensionsStyles
            : null,
          showLoader && loaderStyles,
          showLoader && animateLoader && !this.state.isImageLoaded
            ? css`
                animation: react-placeholder-pulse 1.5s infinite;
              `
            : css`
                animation: none;
              `,
          loaderColor &&
            css`
              background-color: ${loaderColor};
            `,
          typeof heightPercent === 'number'
            ? css`
                padding-bottom: ${heightPercent}%;
              `
            : null,
          typeof loaderHeightPercent === 'number' && !this.state.isImageLoaded
            ? css`
                padding-bottom: ${loaderHeightPercent}%;
              `
            : null
        ]}
        {...rest}
      >
        <div css={[...fadeInStyles]}>
          <div
            css={[
              cover && coverStyles,
              cover &&
                css`
                  background-image: url(${src});
                `
            ]}
          >
            <img css={imgStyles} src={src} alt={alt || ''} />
          </div>
        </div>
      </div>
    )
  }
}

export default Image
